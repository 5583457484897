
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmFormLine,
  },
  setup() {
    const number = ref<number>(0)
    const numberNegative = ref<number>()

    return {
      number,
      numberNegative,
    }
  },
})
